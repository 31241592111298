// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iButton {
  width: 100%;
  max-width: 500px;
  border-radius: 4px;
}
.iButton .iBtnBox {
  padding: 1rem;
  background-color: #5B62C0;
  color: white;
  border-radius: 4px;
}
.iButton .iBtnFlex {
  padding: 1rem 1.5rem;
  max-height: 400px;
  height: 400px;
  overflow: auto;
}
.iButton .iBtnErrorFlex {
  padding: 1rem 1.5rem;
  width: 500px;
  height: 450px;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/LeadershipBehaviour/leadership.scss"],"names":[],"mappings":"AAGA;EACI,WAAA;EACA,gBAAA;EACC,kBAAA;AAFL;AAII;EACI,aAAA;EACA,yBAAA;EACA,YAAA;EACC,kBAAA;AAFT;AAII;EACI,oBAAA;EACA,iBAAA;EACA,aAAA;EACA,cAAA;AAFR;AAII;EACI,oBAAA;EACA,YAAA;EACA,aAAA;EACA,cAAA;AAFR","sourcesContent":[".LeadershipBehaviour {\n}\n\n.iButton{\n    width:100%;\n    max-width: 500px;\n     border-radius: 4px;\n\n    .iBtnBox{\n        padding: 1rem;\n        background-color: #5B62C0;\n        color: white;\n         border-radius: 4px;\n    }\n    .iBtnFlex{\n        padding: 1rem 1.5rem;\n        max-height: 400px;\n        height: 400px;\n        overflow: auto;\n    }\n    .iBtnErrorFlex{\n        padding: 1rem 1.5rem;\n        width: 500px;\n        height: 450px;\n        overflow: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
