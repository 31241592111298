// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ITSMApprovalDetails .statusColor {
  border-width: 1px;
  border-left-width: 6px;
  border-style: solid;
  padding: 5px;
  height: 25px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/ITSMApprovalDetails/ITSMApprovalDetails.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AAAR","sourcesContent":[".ITSMApprovalDetails {\n    .statusColor {\n        border-width: 1px;\n        border-left-width: 6px;\n        border-style: solid;\n        padding: 5px;\n        height: 25px;\n        width: 120px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        border-radius: 5px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
