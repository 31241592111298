// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.taskModule {
  min-height: calc(100vh - 180px);
  overflow: auto;
}

.textStyle:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/TeamFeedback/teamFeedback.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;EACA,cAAA;AACJ;;AAGA;EACI,0BAAA;AAAJ","sourcesContent":[".taskModule {\n    min-height: calc(100vh - 180px);\n    overflow: auto\n\n}\n\n.textStyle:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
