// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LeaveDetail .box-table {
  margin: 0;
  padding: 0;
  position: relative;
  border: 1px solid #efefef;
  border-radius: 4px;
  overflow: overlay;
}
.LeaveDetail .tableHead {
  position: sticky;
  top: 0;
  right: 0;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/LeaveDetails/leaveDetails.scss"],"names":[],"mappings":"AACE;EACE,SAAA;EACA,UAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;EAEA,iBAAA;AADJ;AAKE;EAEE,gBAAA;EACA,MAAA;EACA,QAAA;EACA,UAAA;AAJJ","sourcesContent":[".LeaveDetail {\n  .box-table {\n    margin: 0;\n    padding: 0;\n    position: relative;\n    border: 1px solid #efefef;\n    border-radius: 4px;\n    // height: calc(100vh - 150px);\n    overflow: overlay;\n    // padding-bottom: 1rem;\n  }\n\n  .tableHead {\n  // background: #f9fbfc;\n    position: sticky;\n    top: 0;\n    right: 0;\n    z-index: 1;\n  //   &:hover {\n  // // background: #f9fbfc;\n  //   }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
