// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ITSMApprovals .statusColor {
  border-width: 1px;
  border-left-width: 6px;
  border-style: solid;
  padding: 5px;
  height: 25px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.ITSMApprovals .box-table {
  margin: 0;
  padding: 0;
  position: relative;
  border: 1px solid #efefef;
  border-radius: 4px;
  height: calc(100vh - 140px);
  overflow: overlay;
}
.ITSMApprovals .tableHead {
  position: sticky;
  top: 0;
  right: 0;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/container/ITSMApprovals/itsmApproval.scss"],"names":[],"mappings":"AAEI;EACI,iBAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AADR;AAIK;EACG,SAAA;EACA,UAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,2BAAA;EACA,iBAAA;AAFR;AAKM;EACE,gBAAA;EACA,MAAA;EACA,QAAA;EACA,UAAA;AAHR","sourcesContent":[".ITSMApprovals{\n\n    .statusColor {\n        border-width: 1px;\n        border-left-width: 6px;\n        border-style: solid;\n        padding: 5px;\n        height: 25px;\n        width: 120px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        border-radius: 5px;\n    }\n    \n     .box-table {\n        margin: 0;\n        padding: 0;\n        position: relative;\n        border: 1px solid #efefef;\n        border-radius: 4px;\n        height: calc(100vh - 140px);\n        overflow: overlay;\n      }\n    \n      .tableHead {\n        position: sticky;\n        top: 0;\n        right: 0;\n        z-index: 1;\n      }\n    \n    }\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
