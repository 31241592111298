// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Document {
  height: 100vh;
}
.Document .segment-element {
  padding: 0;
  border-radius: 5px;
}
.Document .back-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.Document .back-btn:hover {
  color: rgb(91, 95, 199);
  cursor: pointer;
}
.Document .back-btn:hover svg {
  fill: rgb(91, 95, 199);
}
.Document .moduleHeader {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(91, 98, 192, 0.05) 100%);
  padding: 0.2rem 1rem;
  margin: 0;
}
.Document .file-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 0.75px solid #D6D6D6;
  gap: 10px;
  margin-bottom: 16px;
  max-width: 350px;
  width: 100%;
  margin: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/DocumentCenter/document.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;AAAJ;AAEI;EACI,UAAA;EACA,kBAAA;AAAR;AAGI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AADR;AAGI;EAII,uBAAA;EACJ,eAAA;AAJJ;AAAQ;EACI,sBAAA;AAEZ;AAII;EACM,+FAAA;EAKR,oBAAA;EACA,SAAA;AANF;AASI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,4BAAA;EACA,SAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,WAAA;AAPR","sourcesContent":[".Document{\n    // background: #f4f4f4;\n    height: 100vh;\n\n    .segment-element{\n        padding: 0;\n        border-radius: 5px;\n    }\n\n    .back-btn{\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        gap: 10px;\n    }\n    .back-btn:hover{\n        svg{\n            fill:rgb(91, 95, 199)\n        }\n        color: rgb(91, 95, 199);\n    cursor: pointer;\n    }\n\n    .moduleHeader{\n          background: linear-gradient(\n    180deg,\n    rgba(255, 255, 255, 0.05) 0%,\n    rgba(91, 98, 192, 0.05) 100%\n  );\n  padding: 0.2rem 1rem;\n  margin: 0;\n    }\n\n    .file-box{\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        padding: 1rem;\n        border: 0.75px solid #D6D6D6;\n        gap: 10px;\n        margin-bottom: 16px;\n        max-width: 350px;\n        width: 100%;\n        margin: 8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
