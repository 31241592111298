// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.on-hover:hover {
  background-color: #f8f8f8;
  cursor: pointer;
}

.teamList {
  overflow: auto;
}

.manager_name {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 155px;
}

.selectTeamMwmber {
  background: linear-gradient(27.54deg, #532F91 -28.21%, rgba(117, 82, 203, 0.94) 42.29%, #01A4E7 118.51%);
  color: white;
  padding: 0.625rem;
  cursor: pointer;
}

.unSelectTeamMwmber {
  padding: 0.625rem;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/TeamMembers/teamMembers.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,eAAA;AACF;;AAEA;EAGE,cAAA;AADF;;AAIA;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;AADF;;AAKA;EACE,wGAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;AAFF;;AAKA;EACE,iBAAA;EACA,eAAA;AAFF","sourcesContent":[".on-hover:hover {\n  background-color: #f8f8f8;\n  cursor: pointer;\n}\n\n.teamList {\n  // min-height: calc(100vh - 496px);\n  // height: 100%;\n  overflow: auto;\n}\n\n.manager_name{\n  font-size: 16px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 155px;\n}\n\n\n.selectTeamMwmber{\n  background: linear-gradient(27.54deg, #532F91 -28.21%, rgba(117, 82, 203, 0.94) 42.29%, #01A4E7 118.51%);\n  color: white;\n  padding: 0.625rem;\n  cursor: pointer;\n}\n\n.unSelectTeamMwmber{\n  padding: 0.625rem;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
