import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, useNavigate, } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootSchema } from "../redux/reducer";
import Sidebar from "../components/Sidebar";
import LazyFallBack from "../components/LazyFallBack";
import { IState } from "../App";

import ApprovalLeave from "../components/ApprovalLeave";
import DeleteApproveLeave from "../components/DeleteApproveLeave";
import ApplyLeave from "../components/ApplyLeave";
import LeaveDetails from "../components/LeaveDetails";
import ViewLeave from "../components/ViewLeave";
import AttendanceEnquiry from "../components/AttendanceEnquiry";
import DocumentCenter from "../components/DocumentCenter";
import IncidentStatus from "../components/IncidentStatus";
import RaiseTicket from "../components/RaiseTicket";
import ITSMApprovals from "../container/ITSMApprovals";

import ViewStatus from "../components/ViewStatus";
import MyGoals from "../components/MyGoals";
import LeadershipBehaviour from "../components/LeadershipBehaviour";
import CFMFeedbackTaskModule from "../components/CFMFeedback";
import CFMTaskModules from "../components/CFMTaskModule";
import SAPStatus from "../components/SAPStatus";
import SAPReqIdCreation from "../components/SAPReqIdCreation";
import RaiseImacRequest from "../components/RaiseImacRequest";
import ACRApprovals from "../container/ACRApprovals";
import ACRRequestDetails from "../components/ACRRequestDetails";
import { Segment } from "@fluentui/react-northstar";
import NFA from "../container/NFA";

// const RaiseTicket = React.lazy(() => import("../components/RaiseTicket"));
// const DocumentCenter = React.lazy(() => import("../components/DocumentCenter"));
const ManagerFeedback = React.lazy(() => import("../container/ManagerFeedback"));
// const MyGoals = React.lazy(() => import("../components/MyGoals"));
// const LeadershipBehaviour = React.lazy(() => import("../components/LeadershipBehaviour"));
// const AttendanceEnquiry = React.lazy(() => import("../components/AttendanceEnquiry"));
const CFM = React.lazy(() => import("../container/CFM"));
// const CFMFeedbackTaskModule = React.lazy(() => import("../components/CFMFeedback"));
// const CFMTaskModules = React.lazy(() => import("../components/CFMTaskModule"));
const Approvals = React.lazy(() => import("../container/ManagerApprovals"));
// const DeleteApproveLeave = React.lazy(() => import("../components/DeleteApproveLeave"));
const ITSM = React.lazy(() => import("../container/ITSupport"));
const ManageLeaves = React.lazy(() => import("../container/ManageLeaves"));
// const ViewLeave = React.lazy(() => import("../components/ViewLeave"));
const VaccinationStatus = React.lazy(() => import("../container/VaccinationStatus"));
// const LeaveDetails = React.lazy(() => import("../components/LeaveDetails"));
// const ApplyLeave = React.lazy(() => import("../components/ApplyLeave"));
const Dashboard = React.lazy(() => import("../container/Dashboard"));
// const ITSMApprovals = React.lazy(() => import("../container/ITSMApprovals"));
const ApprovalCard = React.lazy(() => import("../container/ApprovalCard"));
const Teams = React.lazy(() => import("../container/Teams"));
// const RaiseImacRequest = React.lazy(() => import("../components/RaiseImacRequest"));
// const IncidentStatus = React.lazy(() => import("../components/IncidentStatus"));
const MTM = React.lazy(() => import("../container/MTM"));
const VisitingCards = React.lazy(() => import("../container/VisitingCards"));
const Channels = React.lazy(() => import("../components/Channels"));
// const SAPReqIdCreation = React.lazy(() => import("../components/SAPReqIdCreation"));
const ITServiceDesk = React.lazy(() => import("../container/ITServiceDesk"));
// const ViewStatus = React.lazy(() => import("../components/ViewStatus"));
// const SAPStatus = React.lazy(() => import("../components/SAPStatus"));
// import VisitingCardTaskModule from "../container/VisitingCards";
const PaySpace = React.lazy(() => import("../container/PaySpace"));
const ACRDashboard = React.lazy(() => import("../container/ACRDashboard"));

interface IRoutesProps {
  QueryData: IState;
}

const Redirect = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate("/support-desk");
  }, []);
  return (
    <React.Fragment>
      <LazyFallBack />
    </React.Fragment>
  );
};

const RouteList: React.FC<IRoutesProps> = ({ QueryData }) => {

  const state = useSelector((state: RootSchema) => state);

  return (
    <BrowserRouter>
      {
        state.authData.userAuth.isSuccess && state.authData.userAuth.status_code === "200" ?
          <Routes>
            <Route
              path="/" element={
                <Suspense fallback={<LazyFallBack />}>
                  <Dashboard QueryData={QueryData} />
                </Suspense>
              }
            />
            <Route path="/apply" element={
              <ApplyLeave QueryData={QueryData} />
            } />
            <Route path="/document-center" element={<DocumentCenter QueryData={QueryData} />} />
            <Route
              path="/leave-approve"
              element={
                <ApprovalLeave QueryData={QueryData} />
              }
            />
            <Route
              path="/vaccination"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData} >
                    <VaccinationStatus QueryData={{ ...QueryData, employeeId: parseInt(QueryData.employeeId as string).toString() }} />
                  </Sidebar>
                </Suspense>
              }
            />
            <Route
              path="/manage-leaves"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData} >
                    <ManageLeaves QueryData={QueryData} />
                  </Sidebar>
                </Suspense>
              }
            />
            <Route
              path="/leadership"
              element={<LeadershipBehaviour QueryData={QueryData} managerView={false} />}
            />
            <Route path="/mygoals" element={<MyGoals QueryData={QueryData} managerView={false} />} />
            <Route
              path="/attendance"
              element={<AttendanceEnquiry QueryData={QueryData} />}
            />
            <Route
              path="/view-leave-status"
              element={<LeaveDetails QueryData={QueryData} />}
            />
            <Route
              path="/view-leave"
              element={<ViewLeave QueryData={QueryData} />}
            />
            <Route
              path="/delete-approve-leave"
              element={<DeleteApproveLeave QueryData={QueryData} />}
            />
            <Route path="/cfm-task-module" element={<CFMTaskModules QueryData={QueryData} />} />
            <Route
              path="/cfm"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData} >
                    <CFM QueryData={QueryData} />
                  </Sidebar>
                </Suspense>
              }
            />
            <Route
              path="/approval"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData} >
                    <Approvals QueryData={QueryData} />
                  </Sidebar>
                </Suspense>
              }
            />
            <Route
              path="/feedback-management"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData} >
                    <ManagerFeedback QueryData={QueryData} />
                  </Sidebar>
                </Suspense>
              }
            />
            <Route
              path="/cfm-team-member-feedback"
              element={<CFMFeedbackTaskModule QueryData={QueryData} />}
            />
            <Route
              path="/raise-itsm-ticket"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData} >
                    <ITSM QueryData={QueryData} />
                  </Sidebar>
                </Suspense>
              }
            />
            <Route
              path="/support-desk"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData} >
                    {/* <ITSM QueryData={QueryData} /> */}
                    <ITServiceDesk />
                  </Sidebar>
                </Suspense>
              }
            />
            <Route
              path="/raise-ticket"
              element={<RaiseTicket QueryData={QueryData} />}
            />
            <Route
              path="/incident-status"
              element={<IncidentStatus QueryData={QueryData} />}
            />
            <Route
              path="/imac-approval"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData} >
                    <ITSMApprovals QueryData={QueryData} isManager={true} />
                  </Sidebar>
                </Suspense>
              }
            />
            <Route
              path="/acr-initiator"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData} >
                    <ACRApprovals QueryData={QueryData} initiator={true} />
                  </Sidebar>
                </Suspense>
              }
            />
            <Route
              path="/acr-approvals"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData} >
                    <ACRApprovals QueryData={QueryData} />
                  </Sidebar>
                </Suspense>
              }
            />
           {/* <Route
              path="/acr-dashboard"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData} >
                    <ACRDashboard QueryData={QueryData} initiator={true} />
                  </Sidebar>
                </Suspense>
              }
            /> */}
            <Route
              path="/acr-details/:acrNo/:sysId"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData} >
                    <Segment styles={{ margin: 0, padding: 0, borderRadius: "5px !important" }}>
                      <ACRRequestDetails QueryData={QueryData} />
                    </Segment>
                  </Sidebar>
                </Suspense>
              }
            />
            <Route
              path="/card-approval"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData} >
                    <ApprovalCard QueryData={QueryData} />
                  </Sidebar>
                </Suspense>
              }
            />
            <Route
              path="/imac-approvals"
              element={<ITSMApprovals QueryData={QueryData} isManager={false} />}
            />
            <Route
              path="/manager-view" element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData}>
                    <Teams />
                  </Sidebar>
                </Suspense>
              }
            />
            <Route
              path="/raise-imac-request" element={<RaiseImacRequest QueryData={QueryData} />}
            />
            <Route
              path="/mtm" element={
                <Suspense fallback={<LazyFallBack />}>
                  <MTM QueryData={QueryData} />
                </Suspense>
              }
            />
            <Route
              path="/visiting-cards"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData}>
                    <VisitingCards QueryData={QueryData} />
                  </Sidebar>
                </Suspense>
              }
            />
            <Route
              path="/digital-visiting-module"
              element={
                <Suspense fallback={""}>
                  <VisitingCards QueryData={QueryData} />
                </Suspense>
              }
            />
            <Route
              path="/physical-visiting-module"
              element={
                <Suspense fallback={""}>
                  <VisitingCards QueryData={QueryData} createType="Physical" />
                </Suspense>
              }
            />
            <Route
              path="/status-visiting-module"
              element={<ViewStatus QueryData={QueryData} />}
            />
            <Route
              path="/channels"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Channels QueryData={QueryData} />
                </Suspense>
              }
            />
            {/* <Route
              path="/req-id-creation"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData}>
                    <SAPReqIdCreation QueryData={QueryData} />
                  </Sidebar>
                </Suspense>
              }
            />
            <Route
              path="/sap-id-creation"
              element={<SAPReqIdCreation QueryData={QueryData} />}
            />
            <Route
              path="/sap-status"
              element={<SAPStatus />}
            />
            <Route
              path="/pay-space"
              element={
                <Suspense fallback={<LazyFallBack />}>
                    <PaySpace QueryData={QueryData} />
                </Suspense>
              }
            /> */}
            <Route
              path="/nfa"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <NFA QueryData={QueryData} />
                </Suspense>
              }
            />
          </Routes> :
          <Routes>
            <Route
              path="/" element={
                <Suspense fallback={<LazyFallBack />}>
                  <Redirect />
                </Suspense>
              }
            />
            <Route
              path="/support-desk"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData} >
                    <ITSM QueryData={QueryData} />
                  </Sidebar>
                </Suspense>
              }
            />
            <Route
              path="/vaccination"
              element={
                <Suspense fallback={<LazyFallBack />}>
                  <Sidebar QueryData={QueryData} >
                    <VaccinationStatus QueryData={{ ...QueryData, employeeId: parseInt(QueryData.employeeId as string).toString() }} />
                  </Sidebar>
                </Suspense>
              }
            />
          </Routes>
      }
    </BrowserRouter >
  );
};

export default RouteList;