// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.pointer {
  cursor: pointer;
}

.carousalClass {
  width: 100% !important;
}
.carousalClass .ui-carrouselpaddles_container {
  display: none;
}
.carousalClass .ui-carrouselpaddles_container, .carousalClass .ui-carousel__itemscontainer, .carousalClass .ui-carousel__item, .carousalClass .bf {
  width: inherit;
}
.carousalClass .mtmImg {
  height: calc(100vh - 600px);
  min-height: 250px;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,8JAAA;EAGA,mCAAA;EACA,kCAAA;AADF;;AAKA;EACE,+EAAA;AAFF;;AAMA;EACE,eAAA;AAHF;;AAMA;EACE,sBAAA;AAHF;AAIE;EACE,aAAA;AAFJ;AAIE;EACE,cAAA;AAFJ;AAIE;EAEE,2BAAA;EACA,iBAAA;AAHJ","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  // background: #f5f5f5;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n.pointer {\n  cursor: pointer;\n}\n\n.carousalClass {\n  width: 100% !important;\n  .ui-carrouselpaddles_container {\n    display: none;\n  }\n  .ui-carrouselpaddles_container, .ui-carousel__itemscontainer, .ui-carousel__item, .bf {\n    width: inherit;\n  }\n  .mtmImg {\n    // width: 100% !important;\n    height: calc(100vh - 600px);\n    min-height: 250px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
