// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IncidentStatus {
  width: 100%;
}
.IncidentStatus .statusColor {
  border-width: 1px;
  border-left-width: 6px;
  border-style: solid;
  padding: 5px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.IncidentStatus .box-table {
  margin: 0;
  padding: 0;
  position: relative;
  border: 1px solid #efefef;
  border-radius: 4px;
  width: inherit;
  max-width: inherit;
  overflow: overlay;
}
.IncidentStatus .tableHead {
  position: sticky;
  top: 0;
  right: 0;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/IncidentStatus/IncidentStatus.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAA;EACI,iBAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;EAEA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AAEC;EACG,SAAA;EACA,UAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;EAEA,iBAAA;AADJ;AAKE;EAEE,gBAAA;EACA,MAAA;EACA,QAAA;EACA,UAAA;AAJJ","sourcesContent":[".IncidentStatus{\n  width: 100%;\n.statusColor {\n    border-width: 1px;\n    border-left-width: 6px;\n    border-style: solid;\n    padding: 5px;\n    // height: 25px;\n    width: 120px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 5px;\n}\n\n .box-table {\n    margin: 0;\n    padding: 0;\n    position: relative;\n    border: 1px solid #efefef;\n    border-radius: 4px;\n    width: inherit;\n    max-width: inherit;\n    // height: calc(100vh - 140px);\n    overflow: overlay;\n    // padding-bottom: 1rem;\n  }\n\n  .tableHead {\n  // background: #f9fbfc;\n    position: sticky;\n    top: 0;\n    right: 0;\n    z-index: 1;\n  //   &:hover {\n  // // background: #f9fbfc;\n  //   }\n  }\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
