import { ArrowLeftIcon, Box, Button, Checkbox, ChevronDownMediumIcon, CloseIcon, Dialog, Divider, DownloadIcon, Dropdown, Flex, Image, InfoIcon, Input, LeaveIcon, Segment, Skeleton, Text, TextArea } from "@fluentui/react-northstar";
import React from "react";
import "./document.scss";
import pdf from "../../assets/img/pdf.svg";
import { useDispatch, useSelector } from "react-redux";
import { setDocumentView } from "../../redux/actions/appConfigration";
import { downloadFileHtmlToPdf, EmpTrushDocument, EmpValueByCode, getDDMMYYYFormat, ServiceName } from "../../constant";
import { RootSchema } from "../../redux/reducer";
import * as DocumentActions from "../../redux/actions/DocumentAction";
import { IState } from "../../App";
import DataNotFound from "../DNF";
import ErrorComponent from "../ErrorComponent";
import ServicePermission from "../HOC/ServicePermission";
interface IDocumentProps {
    QueryData: IState
}
interface IModuleActive {
    payslip?: boolean;
    form16?: boolean;
    trush?: boolean;
    additional?: boolean
}
interface IInfoActive {
    trush?: boolean;
    additional?: boolean
    bonafide?: boolean
}
interface IDropdownValue {
    payslip: { header: string; value: string; };
    paySlipYear: { header: string; value: string; };
    form16: { header: string; value: string; };
    trush: { header: string; value: number; };
}

interface IBonafide {
    dateOfJoining: boolean;
    permanentAdd: boolean;
    currentAdd: boolean;
    concern: string;
    reason: string;
}

/**
 * This function handle the loading process unit document API is pending or not giving response
 * @returns 
 */
const SkeletonLoading = () => {
    return (
        <Skeleton animation="pulse">
            <Flex wrap>
                {[1, 2, 3].map((item) => {
                    return (
                        <Box key={item} className="file-box">
                            <Skeleton.Line width="47px" height="47px" />
                            <Flex column gap="gap.smaller" fill>
                                <Skeleton.Text />
                                <Skeleton.Text />
                            </Flex>
                            <Skeleton.Button iconOnly />
                        </Box>
                    );
                })}
            </Flex>
        </Skeleton>
    );
};
const DocumentCenter: React.FC<IDocumentProps> = ({ QueryData }) => {
    const [isModuleActive, setIsModuleActive] = React.useState<IModuleActive>({ payslip: true });
    const [isInfoActive, setIsInfoActive] = React.useState<IInfoActive>({});
    const [isDocumentType, setIsDocumentType] = React.useState<string>("Current Documents");
    //const [form16Data, setForm16Data]= React.useState<any>();
    const state = useSelector((state: RootSchema) => state);
    const accessToken = state.authData.userAuth.token;
    const empCode = EmpValueByCode[state.authData.userAuth.comp_code as keyof typeof EmpValueByCode];
    const [isDropdownValue, setIsDropdownValue] = React.useState<IDropdownValue>({
        payslip: { header: "", value: "" },
        paySlipYear: { header: "", value: "" },
        form16: { header: "", value: "" },
        trush: { header: "", value: 0 },
    });
    const [isBonafide, setIsBonafide] = React.useState<IBonafide>({
        dateOfJoining: false,
        permanentAdd: false,
        currentAdd: false,
        concern: "",
        reason: ""
    });
    const dispatch = useDispatch();
    const OverviewButton = () => {
        dispatch(setDocumentView(false));
    };

    /**
     * 
     */
    function isBonafideResetHandler() {
        setIsBonafide({
            dateOfJoining: false,
            permanentAdd: false,
            currentAdd: false,
            concern: "",
            reason: ""
        });
    }

    const payslipDropdown: { header: string; value: string; }[] = isDocumentType === "Current Documents" ?
        state.documentData.currentMasterData.isSuccess && state.documentData.currentMasterData.status_code === "200" ?
            state.documentData.currentMasterData.data.payslip_months.map((item) => {
                return {
                    header: item.month,
                    value: item.month_count
                };
            })
            : [] :
        state.documentData.previousMasterData.isSuccess && state.documentData.previousMasterData.status_code === "200" ?
            state.documentData.previousMasterData.payslip_months.map((item) => {
                return {
                    header: item.month,
                    value: item.month_count
                };
            })
            : [];

    const payslipYearDropdown: { header: string; value: string; }[] =
        state.documentData.currentMasterData.isSuccess && state.documentData.currentMasterData.status_code === "200" ?
            state.documentData.currentMasterData.data.payslip_years.map((item) => {
                return {
                    header: item,
                    value: item
                };
            })
            : [];

    const [assesmentDropdown, setAssesmentDropdown] = React.useState<{ header: string, value: string; }[]>();
    //= state.documentData.currentMasterData.isSuccess && state.documentData.currentMasterData.status_code === "200" ?
    React.useEffect(() => {
        if (isDocumentType === "Current Documents" && state.documentData.currentMasterData.status_code === "200") {

            const data = state.documentData.currentMasterData.data.assesment_years.map((item) => {
                return {
                    header: item,
                    value: item
                };
            });
            setIsDropdownValue({ ...isDropdownValue, form16: data[0] });
            setAssesmentDropdown(data);

        }
        else if (state.documentData.previousMasterData.status_code === "200") {

            const data = state.documentData.previousMasterData.assesment_years.map((item) => {
                return {
                    header: item,
                    value: item
                };
            });

            setIsDropdownValue({ ...isDropdownValue, form16: data[0] });
            setAssesmentDropdown(data);
        }
    }, [state.documentData.previousMasterData.isSuccess, state.documentData.currentMasterData.isSuccess, isDocumentType, isDropdownValue.payslip, isDropdownValue.paySlipYear]);

    const financialDropdown: { header: string; value: number; }[] = state.documentData.currentMasterData.isSuccess && state.documentData.currentMasterData.status_code === "200" ?
        state.documentData.currentMasterData.data.financial_years.map((item) => {
            return {
                header: "FY " + item.fy_year,
                value: item.value
            };
        })
        : [];

    const payslipData = isDocumentType === "Current Documents" ? state.documentData.currentpayslipWorksheetData : state.documentData.previousPayslipData;
    //let form16Data = isDocumentType === "Current Documents" ? state.documentData.formSixteenData : state.documentData.oldDocumentData
    const [form16Data, setForm16Data] = React.useState<DocumentActions.IFormSixteenData>(state.documentData.formSixteenData);
    React.useEffect(() => {
        if (isDocumentType === "Current Documents") {
            setForm16Data(state.documentData.formSixteenData);
        }
        else {
            setForm16Data(state.documentData.oldDocumentData);
        }

    }, [state.documentData.formSixteenData.isSuccess, state.documentData.oldDocumentData.isSuccess, isDocumentType]);
    const getMasterDropdwonData = () => {
        const payload = { doj: state.userInfo.userManagerData?.user_details[0]?.Doj, emp_id: QueryData.employeeId as string };
        isDocumentType === "Current Documents" ?
            !(state.documentData.currentMasterData.status_code === "200") && dispatch(DocumentActions.getCurrentMasterDataAction(accessToken, empCode, payload)) :
            !(state.documentData.previousMasterData.status_code === "200") && dispatch(DocumentActions.getPreviousMasterDataAction(accessToken, empCode, { emp_id: QueryData.employeeId as string }));
    };

    /**
     * This function will download the user's file
     * @param payload 
     */
    const getFileDownload = (payload: {
        company: string,
        document: string,
        document_type: string,
        financial_year?: string,
        month: string,
        year: number,
        path: string
    }) => {
        if (isDocumentType === "Current Documents") {
            const Payload: DocumentActions.ICurrentDownloadDocumentDataPayLoad = {
                path: payload.path
            };
            dispatch(DocumentActions.getCurrentDownloadDocumentDataAction(accessToken, empCode, Payload));
        } else {
            const Payload: DocumentActions.IPreviousDownloadDocumentDataPayLoad = {
                emp_id: QueryData.employeeId as string,
                ...payload
            };
            dispatch(DocumentActions.getPreviousDownloadDocumentDataAction(accessToken, empCode, Payload));
        }
    };
    const getPFAndPensionSuperAnnuationStatementDownload = (year: number, type: string) => {
        const payload: DocumentActions.IDownloadTrustFundDataPayLoad = {
            emp_id: QueryData.employeeId as string,
            fin_year: year.toString(),
            annual_type: type
        };
        dispatch(DocumentActions.getDownloadTrustFundDataAction(accessToken, empCode, payload));
    };
    const getPFPassbookDownload = () => {
        const payload: DocumentActions.IPassbookDetailDataPayLoad = {
            emp_id: QueryData.employeeId as string,
        };
        dispatch(DocumentActions.getPassbookDetailDataAction(accessToken, empCode, payload));
    };

    const getAdditionalDocumentDownload = (type: string, fileName: string) => {
        const payload: DocumentActions.IAdditionalDownloadDocumentDataPayLoad = {
            emp_id: QueryData.employeeId as string,
            document_type: type,
            file_name: fileName
        };
        dispatch(DocumentActions.getAdditionalDownloadDocumentDataAction(accessToken, empCode, payload));
    };

    const getGenerateBonafideCertificate = () => {
        const payload: DocumentActions.IGenerateBonafideDataPayLoad = {
            emp_id: QueryData.employeeId as string,
            template_code: "bonafide_certificate",
            concern_authorities: isBonafide.concern,
            reason: isBonafide.reason,
            joining_date: isBonafide.dateOfJoining,
            current_address: isBonafide.currentAdd,
            permanent_address: isBonafide.permanentAdd,
        };
        dispatch(DocumentActions.getGenerateBonafideDataAction(accessToken, empCode, payload));
    };

    React.useEffect(() => {
        if (state.userInfo.userManagerData.isSuccess && state.userInfo.userManagerData.status_code === "200") {
            getMasterDropdwonData();
        }
    }, [state.userInfo.userManagerData.isSuccess && state.userInfo.userManagerData.status_code === "200", isDocumentType]);
    React.useEffect(() => {
        if (isModuleActive.payslip && isDropdownValue.payslip !== undefined && isDropdownValue.payslip?.value !== "" && isDropdownValue.paySlipYear !== undefined && isDropdownValue.paySlipYear?.value !== "") {
            if (isDocumentType === "Current Documents") {
                const payload: DocumentActions.ICurrentPayslipWorksheetPayLaod = {
                    emp_id: QueryData.employeeId as string,
                    month_number: isDropdownValue.payslip?.value,
                    year: isDropdownValue.paySlipYear.value,
                };
                dispatch(DocumentActions.getCurrentPayslipWorksheetDataAction(accessToken, empCode, payload));
            } else {
                const payload: DocumentActions.IPreviousPayslipDataPayLoad = {
                    emp_id: QueryData.employeeId as string,
                    document_type: "PaySlip",
                    month_number: isDropdownValue.payslip?.value,
                    year: isDropdownValue.payslip?.header?.split("-")[1] ? isDropdownValue.payslip?.header?.split("-")[1] : new Date().getFullYear().toString()
                };
                dispatch(DocumentActions.getPreviousPayslipDataAction(accessToken, empCode, payload));
            }
        }
        else if (isModuleActive.form16 && isDropdownValue.form16 !== undefined && isDropdownValue.form16?.value !== "") {
            if (isDocumentType === "Current Documents") {
                const payload: DocumentActions.IFormSixteenDataPayLoad = {
                    emp_id: QueryData.employeeId as string,
                    assesment_year: isDropdownValue.form16?.value
                };
                dispatch(DocumentActions.getFormSixteenDataAction(accessToken, empCode, payload));
            } else {
                const payload: DocumentActions.IOldDocumentDataPayLoad = {
                    emp_id: QueryData.employeeId as string,
                    assesment_year: isDropdownValue.form16?.value
                };
                dispatch(DocumentActions.getOldDocumentDataAction(accessToken, empCode, payload));
            }
        }
        else if (isModuleActive.trush && isDropdownValue.trush !== undefined && isDropdownValue.trush?.header !== "") {
            const payload: DocumentActions.IEmployeeApplicableDataPayLoad = {
                emp_id: QueryData.employeeId as string,
                finanical_year: isDropdownValue.trush?.value
            };
            dispatch(DocumentActions.getEmployeeApplicableDataAction(accessToken, empCode, payload));
        }
        else if (isModuleActive.additional) {
            const payload: DocumentActions.IEmployeeAdditionalDataPayLoad = {
                emp_id: QueryData.employeeId as string
            };
            !(state.documentData.employeeLatestData.status_code === "200") && dispatch(DocumentActions.getEmployeeLatestDataAction(accessToken, empCode, payload));
            !(state.documentData.employeeAdditionalData.status_code === "200") && dispatch(DocumentActions.getEmployeeAdditionalDataAction(accessToken, empCode, payload));
        }
        else { }
    }, [isModuleActive, isDropdownValue]);

    React.useEffect(() => {
        if ((state.documentData.currentMasterData.isSuccess && state.documentData.currentMasterData.status_code === "200") || (state.documentData.previousMasterData.isSuccess && state.documentData.previousMasterData.status_code === "200")) {
            setIsDropdownValue({
                payslip: payslipDropdown[0],
                paySlipYear: payslipYearDropdown[0],
                form16: assesmentDropdown && assesmentDropdown.length ? assesmentDropdown[0] : { header: "", value: "" },
                trush: financialDropdown[0],
            });
        }
    }, [state.documentData.currentMasterData.isSuccess && state.documentData.currentMasterData.status_code === "200",
    state.documentData.previousMasterData.isSuccess && state.documentData.previousMasterData.status_code === "200",
    isDocumentType === "Current Documents" && state.documentData.currentMasterData.status_code === "200" && state.documentData.previousMasterData.status_code === "200"
    ]);

    React.useEffect(() => {
        if (state.documentData.generateBonafideData.isSuccess) isBonafideResetHandler();
    }, [state.documentData.generateBonafideData.isSuccess]);

    return (
        <React.Fragment>
            <Flex gap="gap.medium" column className='Document'
                styles={({ theme: { siteVariables } }) => ({
                    background: siteVariables.colorScheme.grey.backgroundActive,
                    padding: state.appConfig.dimension.deviceWidth <= 600 || QueryData.isTaskModuleActive ? "1rem" : "2rem"
                })}
            >
                <Flex vAlign='center' hAlign='center' space='between' wrap gap="gap.small">
                    {!QueryData.isTaskModuleActive && <Box className='back-btn' onClick={() => OverviewButton()} >
                        <ArrowLeftIcon size="small" />
                        <Text content="Overview" weight="semibold" />
                    </Box>}
                    <Flex gap="gap.medium">
                        {state.authData.userAuth.isSuccess && empCode !== "cv" && <Flex gap='gap.small' vAlign='center' wrap>
                            <Text content="Document Type" style={{ whiteSpace: "nowrap" }} weight="semibold" />
                            <Dropdown
                                fluid
                                inverted
                                className='inputBody'
                                styles={{ width: "16rem" }}
                                items={[
                                    "Current Documents",
                                    "Previous Company Documents",
                                ]}
                                value={isDocumentType}
                                onChange={(e: any, v: any) => {
                                    setIsDropdownValue({
                                        payslip: { header: "", value: "" },
                                        paySlipYear: { header: "", value: "" },
                                        form16: { header: "", value: "" },
                                        trush: { header: "", value: 0 },
                                    });
                                    setIsModuleActive({ trush: false, payslip: true, form16: false });
                                    setIsDocumentType(v.value);
                                }}
                            />
                        </Flex>}
                        <Button content="Generate Bonafide" primary onClick={() => setIsInfoActive({ bonafide: true })} />
                    </Flex>
                </Flex>
                <Segment className='segment-element' >
                    <Flex className='moduleHeader' vAlign='center' hAlign='start' space="between">
                        <Text size='medium' weight='bold' styles={{ whiteSpace: "nowrap" }}>Monthly Payslip</Text>
                        <Flex wrap gap="gap.large" hAlign='end'>
                            {isModuleActive.payslip &&
                                <ServicePermission service_name={[ServiceName.MONTHLY_PAYSLIP]} showReason={false}>
                                    <Flex wrap gap="gap.small" hAlign='end' >
                                        {isDocumentType === "Current Documents" && <Dropdown
                                            key={isDocumentType}
                                            placeholder='Please Select Year' fluid
                                            inverted
                                            className='inputBody'
                                            styles={{ width: "6rem" }}
                                            items={payslipYearDropdown}

                                            value={isDropdownValue.paySlipYear?.header}
                                            onChange={(e: any, v: any) => setIsDropdownValue({ ...isDropdownValue, paySlipYear: v.value })}
                                        />}
                                        <Dropdown
                                            key={isDocumentType + "Year"}
                                            placeholder='Please Select Month' fluid
                                            inverted
                                            className='inputBody'
                                            styles={{ width: isDocumentType === "Current Documents" ? "8rem" : "11rem" }}
                                            items={payslipDropdown}
                                            value={isDropdownValue.payslip?.header}
                                            onChange={(e: any, v: any) => setIsDropdownValue({ ...isDropdownValue, payslip: v.value })}
                                        />
                                    </Flex>

                                </ServicePermission>
                            }
                            <Button icon={<ChevronDownMediumIcon rotate={!isModuleActive.payslip ? 0 : 180} />} text iconOnly onClick={() => setIsModuleActive({ payslip: !isModuleActive.payslip })} />
                        </Flex>
                    </Flex>
                    <Divider fitted />
                    <Box>
                        {
                            isModuleActive.payslip &&
                            <ServicePermission service_name={[ServiceName.MONTHLY_PAYSLIP]} showReason={true}>
                                <Flex styles={{ padding: "1rem" }} gap="gap.large" wrap>
                                    {payslipData.isSuccess && payslipData.status_code === "200" && state.documentData.currentpayslipWorksheetData.data.payslip_month_list.length > 0 ?
                                        state.documentData.currentpayslipWorksheetData.data.payslip_month_list.map((item, index) => {
                                            return (
                                                <Box key={index} className="file-box">
                                                    <Image src={pdf} width="47px" height="47px" />
                                                    <Flex column >
                                                        <Text content={"Monthly Payslip"} weight="semibold" />
                                                        <Text size="small" content={"This document gives you information of your income, deductions and total paid days of - " + isDropdownValue.payslip?.header} weight="semilight" color="grey" styles={{ color: "#717171" }} />
                                                    </Flex>
                                                    <DownloadIcon styles={({ theme: { siteVariables } }) => ({
                                                        color: siteVariables.colorScheme.brand.foreground,
                                                        cursor: "pointer"
                                                    })} onClick={() => {
                                                        const payload = {
                                                            company: item.company,
                                                            document: "Payslip",
                                                            document_type: "monthly",
                                                            financial_year: "",
                                                            month: item.month_number,
                                                            year: item.year,
                                                            path: item.path
                                                        };
                                                        getFileDownload(payload);
                                                    }} />
                                                </Box>
                                            );
                                        })
                                        :
                                        payslipData.isSuccess && payslipData.status_code === "200" && state.documentData.currentpayslipWorksheetData.data.payslip_month_list.length === 0 ?
                                            <DataNotFound />
                                            : payslipData.isSuccess && payslipData.status_code !== "200" ?
                                                <ErrorComponent message={payslipData.message} /> :
                                                <SkeletonLoading />
                                    }
                                </Flex>
                            </ServicePermission>
                        }
                    </Box>
                </Segment>
                <Segment className='segment-element' >
                    <Flex className='moduleHeader' vAlign='center' hAlign='start' space="between">
                        <Text size='medium' weight='bold' styles={{ whiteSpace: "nowrap" }}>Form 16</Text>
                        <Flex wrap gap="gap.large" hAlign='end'>
                            {isModuleActive.form16 && <ServicePermission service_name={[ServiceName.FORM_16]} showReason={false}>
                                <Dropdown
                                    key={isDocumentType + isDropdownValue.form16.header}
                                    placeholder='Please Select Year' fluid
                                    inverted
                                    className='inputBody'
                                    styles={{ width: "10rem" }}
                                    items={assesmentDropdown}
                                    value={isDropdownValue.form16.header}
                                    onChange={(e: any, v: any) => {
                                        setIsDropdownValue({ ...isDropdownValue, form16: v.value });
                                    }}
                                />
                            </ServicePermission>
                            }
                            <Button icon={<ChevronDownMediumIcon rotate={!isModuleActive.form16 ? 0 : 180} />} text iconOnly onClick={() => setIsModuleActive({ form16: !isModuleActive.form16 })} />
                        </Flex>
                    </Flex>
                    <Divider fitted />
                    <Box>
                        {
                            isModuleActive.form16 &&
                            <ServicePermission service_name={[ServiceName.FORM_16]} showReason={true}>
                                <Flex key={"form16" + isDocumentType + Date.now()} styles={{ padding: "1rem" }} wrap>
                                    {form16Data.isSuccess && form16Data.status_code === "200" && form16Data.data?.form16_list.length ?
                                        form16Data.data?.form16_list.map((item, index) => {
                                            return (
                                                <Box className="file-box" key={"form16" + isDocumentType + isDropdownValue.form16.header + item.company + item.financial_year + Date.now()}>
                                                    <Image src={pdf} width="47px" height="47px" />
                                                    <Flex column >
                                                        <Text content={"Form 16 - " + item.type} weight="semibold" />
                                                        <Text size="small" content={`This document gives you Form16 - ${item.type} related information for the AY year-${item.financial_year}`} weight="semilight" color="grey" styles={{ color: "#717171" }} />
                                                    </Flex>
                                                    <DownloadIcon styles={({ theme: { siteVariables } }) => ({
                                                        color: siteVariables.colorScheme.brand.foreground,
                                                        cursor: "pointer"
                                                    })} onClick={() => {
                                                        const payload = {
                                                            company: item.company,
                                                            document: item.type,
                                                            document_type: "yearly",
                                                            financial_year: item.financial_year,
                                                            month: "",
                                                            year: 2022,
                                                            path: item.path
                                                        };
                                                        getFileDownload(payload);
                                                    }}
                                                    />
                                                </Box>
                                            );
                                        })
                                        :
                                        (form16Data.isSuccess && form16Data.status_code === "200" && form16Data.data?.form16_list.length === 0) ?
                                            <DataNotFound /> :
                                            form16Data.isSuccess && form16Data.status_code !== "200" ?
                                                <ErrorComponent message={form16Data.message} /> :
                                                <SkeletonLoading />
                                    }
                                </Flex>
                            </ServicePermission>
                        }

                    </Box>
                </Segment>
                {empCode === "cv" && <Segment className='segment-element' >
                    <Flex className='moduleHeader' vAlign='center' hAlign='start' space="between">
                        <Flex gap='gap.small' vAlign='center'>
                            {/* <LeaveIcon size='medium' /> */}
                            <Text size='medium' weight='bold' styles={{ whiteSpace: "nowrap" }}>Trust fund Documents </Text>
                            <InfoIcon size="small" outline styles={{ cursor: "pointer" }} onClick={() => setIsInfoActive({ trush: true })} />
                        </Flex>
                        <Flex wrap gap="gap.large" hAlign='end'>
                            {isModuleActive.trush && <ServicePermission service_name={[ServiceName.TRUST_FUND_DOCUMENTS]} showReason={false}>
                                <Dropdown
                                    key={isDocumentType}
                                    placeholder='Please Select Year' fluid
                                    inverted
                                    className='inputBody'
                                    styles={{ width: "10rem" }}
                                    items={financialDropdown}
                                    value={isDropdownValue.trush?.header}
                                    onChange={(e: any, v: any) => setIsDropdownValue({ ...isDropdownValue, trush: v.value })}
                                />
                            </ServicePermission>
                            }
                            <Button icon={<ChevronDownMediumIcon rotate={!isModuleActive.trush ? 0 : 180} />} text iconOnly onClick={() => setIsModuleActive({ trush: !isModuleActive.trush })} />
                        </Flex>
                    </Flex>
                    <Divider fitted />
                    <Box>
                        {
                            isModuleActive.trush && <ServicePermission service_name={[ServiceName.TRUST_FUND_DOCUMENTS]} showReason={true}>
                                <Flex styles={{ padding: "1rem" }}>
                                    {
                                        state.documentData.employeeApplicableData.isSuccess && state.documentData.employeeApplicableData.status_code === "200"
                                            && (state.documentData.employeeApplicableData.applicable_for_provident_fund || state.documentData.employeeApplicableData.applicable_for_pension_scheme || state.documentData.employeeApplicableData.applicable_for_superannuation_scheme) ?
                                            <Flex wrap>
                                                {state.documentData.employeeApplicableData.applicable_for_provident_fund && <Box className="file-box">
                                                    <Image src={pdf} width="47px" height="47px" />
                                                    <Flex column >
                                                        <Text content={"PF Statement"} weight="semibold" />
                                                        <Text size="small" content={`Your PF Statement for the financial Year ${isDropdownValue.trush?.value} has been released`} weight="semilight" color="grey" styles={{ color: "#717171" }} />
                                                    </Flex>
                                                    <DownloadIcon styles={({ theme: { siteVariables } }) => ({
                                                        color: siteVariables.colorScheme.brand.foreground,
                                                        cursor: "pointer"
                                                    })} onClick={() => getPFAndPensionSuperAnnuationStatementDownload(isDropdownValue.trush?.value, EmpTrushDocument["PFS"])} />
                                                </Box>}
                                                {state.documentData.employeeApplicableData.applicable_for_pension_scheme && <Box className="file-box">
                                                    <Image src={pdf} width="47px" height="47px" />
                                                    <Flex column >
                                                        <Text content={"Pension Statement"} weight="semibold" />
                                                        <Text size="small" content={`Your Pension Statement for the financial Year ${isDropdownValue.trush?.value} has been released`} weight="semilight" color="grey" styles={{ color: "#717171" }} />
                                                    </Flex>
                                                    <DownloadIcon styles={({ theme: { siteVariables } }) => ({
                                                        color: siteVariables.colorScheme.brand.foreground,
                                                        cursor: "pointer"
                                                    })} onClick={() => getPFAndPensionSuperAnnuationStatementDownload(isDropdownValue.trush?.value, EmpTrushDocument["PS"])} />
                                                </Box>}
                                                {state.documentData.employeeApplicableData.applicable_for_superannuation_scheme && <Box className="file-box">
                                                    <Image src={pdf} width="47px" height="47px" />
                                                    <Flex column >
                                                        <Text content={"Super Annuation Statement"} weight="semibold" />
                                                        <Text size="small" content={`Your Super Annuation Statement for the financial Year ${isDropdownValue.trush?.value} has been released`} weight="semilight" color="grey" styles={{ color: "#717171" }} />
                                                    </Flex>
                                                    <DownloadIcon styles={({ theme: { siteVariables } }) => ({
                                                        color: siteVariables.colorScheme.brand.foreground,
                                                        cursor: "pointer"
                                                    })} onClick={() => getPFAndPensionSuperAnnuationStatementDownload(isDropdownValue.trush?.value, EmpTrushDocument["SAS"])} />
                                                </Box>}
                                            </Flex> :
                                            state.documentData.employeeApplicableData.isSuccess && state.documentData.employeeApplicableData.status_code === "200"
                                                && !(state.documentData.employeeApplicableData.applicable_for_provident_fund || state.documentData.employeeApplicableData.applicable_for_pension_scheme || state.documentData.employeeApplicableData.applicable_for_superannuation_scheme) ?
                                                <DataNotFound /> :
                                                state.documentData.employeeApplicableData.isSuccess && state.documentData.employeeApplicableData.status_code !== "200" ?
                                                    <ErrorComponent message={state.documentData.employeeApplicableData.message} /> :
                                                    <SkeletonLoading />
                                    }
                                </Flex>
                            </ServicePermission>
                        }
                    </Box>
                </Segment>}
                {isDocumentType === "Current Documents" && <Segment className='segment-element' >
                    <Flex className='moduleHeader' vAlign='center' hAlign='start' space="between">
                        <Flex gap='gap.small' vAlign='center'>
                            <Text size='medium' weight='bold' styles={{ whiteSpace: "nowrap" }}>Additional Documents </Text>
                            <InfoIcon size="small" outline styles={{ cursor: "pointer" }} onClick={() => setIsInfoActive({ additional: true })} />
                        </Flex>
                        <Flex gap="gap.large">
                            <Button icon={<ChevronDownMediumIcon rotate={!isModuleActive.additional ? 0 : 180} />} text iconOnly onClick={() => setIsModuleActive({ additional: !isModuleActive.additional })} />
                        </Flex>
                    </Flex>
                    <Divider fitted />
                    <Box>
                        {
                            isModuleActive.additional && <ServicePermission service_name={[ServiceName.ADDITIONAL_DOCUMENTS]} showReason={true}>
                                <Flex styles={{ padding: "1rem" }} >
                                    {state.documentData.employeeLatestData.isSuccess && state.documentData.employeeLatestData.status_code === "200" && state.documentData.employeeAdditionalData.isSuccess
                                        && (state.documentData.employeeLatestData.data.it_sheet_month_list.length > 0 ||
                                            state.documentData.employeeLatestData.data.payslip_month_list.length > 0) ?
                                        <Flex wrap>
                                            {state.documentData.employeeLatestData.data.it_sheet_month_list.length > 0 && <Box className="file-box">
                                                <Image src={pdf} width="47px" height="47px" />
                                                <Flex column >
                                                    <Text content={"Income Tax Worksheet"} weight="semibold" />
                                                    <Text size="small" content={`This document gives you the details of the Income tax worksheet for the - ${state.documentData.employeeLatestData.data.it_sheet_month_list[0].year}`} weight="semilight" color="grey" styles={{ color: "#717171" }} />
                                                </Flex>
                                                <DownloadIcon styles={({ theme: { siteVariables } }) => ({
                                                    color: siteVariables.colorScheme.brand.foreground,
                                                    cursor: "pointer"
                                                })}
                                                    onClick={() => {
                                                        const data = state.documentData.employeeLatestData.data.it_sheet_month_list[0];
                                                        const payload = {
                                                            company: data.company,
                                                            document: "ITSheet",
                                                            document_type: "monthly",
                                                            month: data.month_number,
                                                            year: data.year,
                                                            path: data.path
                                                        };
                                                        getFileDownload(payload);
                                                    }}
                                                />
                                            </Box>}
                                            {empCode === "cv" && <Box className="file-box">
                                                <Image src={pdf} width="47px" height="47px" />
                                                <Flex column >
                                                    <Text content={"PF Passbook"} weight="semibold" />
                                                    <Text size="small" content={"This document gives you the details of PF passbook"} weight="semilight" color="grey" styles={{ color: "#717171" }} />
                                                </Flex>
                                                <DownloadIcon styles={({ theme: { siteVariables } }) => ({
                                                    color: siteVariables.colorScheme.brand.foreground,
                                                    cursor: "pointer"
                                                })}
                                                    onClick={() => getPFPassbookDownload()}
                                                />
                                            </Box>}
                                            {state.documentData.employeeAdditionalData.isSuccess && state.documentData.employeeAdditionalData.status_code === "200" && state.documentData.employeeAdditionalData.data.additional_documents_list.length > 0 &&
                                                state.documentData.employeeAdditionalData.data.additional_documents_list.map((item, index) => {
                                                    return (
                                                        <Box className="file-box" key={index}>
                                                            <Image src={pdf} width="47px" height="47px" />
                                                            <Flex column >
                                                                <Text content={item.title} weight="semibold" />
                                                                <Text size="small" content={item.description} weight="semilight" color="grey" styles={{ color: "#717171" }} />
                                                            </Flex>
                                                            <DownloadIcon styles={({ theme: { siteVariables } }) => ({
                                                                color: siteVariables.colorScheme.brand.foreground,
                                                                cursor: "pointer"
                                                            })} onClick={() => getAdditionalDocumentDownload(item.document_type, item.file_name)} />
                                                        </Box>
                                                    );
                                                })}
                                        </Flex> : state.documentData.employeeLatestData.isSuccess && state.documentData.employeeLatestData.status_code === "200"
                                            && state.documentData.employeeLatestData.data.it_sheet_month_list.length === 0 &&
                                            state.documentData.employeeLatestData.data.payslip_month_list.length === 0 ?
                                            <DataNotFound /> : state.documentData.employeeLatestData.isSuccess && state.documentData.employeeLatestData.status_code !== "200" ?
                                                <ErrorComponent message={state.documentData.employeeLatestData.message} /> :
                                                <SkeletonLoading />
                                    }
                                </Flex>
                            </ServicePermission>
                        }
                    </Box>
                </Segment>}
            </Flex >
            <Dialog
                open={isInfoActive.trush}
                content={
                    <Flex column gap="gap.small">
                        <Divider fitted />
                        <Flex column gap="gap.small">
                            <Flex gap="gap.small">
                                <Text content="1." />
                                <Text content="Statements as applicable (in terms of financial year and trust fund policy) will be available for download below." />
                            </Flex>
                            <Flex gap="gap.small">
                                <Text content="2." />
                                <Text content="Statements on the system are available from 2005 or after date of joining whichever is later." />
                            </Flex>
                            <Flex gap="gap.small">
                                <Text content="3." />
                                <Text content='In case of any queries w.r.t annual statements or passbook entry, please raise to " Trust Fund Operations" team, via gdcsupport.tatamotors.com.' />
                            </Flex>
                        </Flex>
                    </Flex>
                }
                header="Note"
                headerAction={{
                    content: <CloseIcon />,
                    onClick: () => setIsInfoActive({ trush: false }),
                }}
                styles={QueryData.isTaskModuleActive ? { width: "500px", boxShadow: "5px 5px 5px 5px #888888" } : {}}
                overlay={{ styles: QueryData.isTaskModuleActive ? { background: "none", paddingBlock: "5rem" } : {} }}
            />
            <Dialog
                open={isInfoActive.additional}
                content={
                    <Flex column gap="gap.small">
                        <Divider fitted />
                        <Flex column gap="gap.small">
                            <Flex gap="gap.small">
                                <Text content="1." />
                                <Text content="Last two financial year PF details are shown below." />
                            </Flex>
                            <Flex gap="gap.small">
                                <Text content="2." />
                                <Text content="Pension details will be coming soon." />
                            </Flex>
                        </Flex>
                    </Flex>
                }
                header="Note"
                headerAction={{
                    content: <CloseIcon />,
                    onClick: () => setIsInfoActive({ additional: false }),
                }}
                styles={QueryData.isTaskModuleActive ? { width: "500px", boxShadow: "5px 5px 5px 5px #888888" } : {}}
                overlay={{ styles: QueryData.isTaskModuleActive ? { background: "none", paddingBlock: "5rem" } : {} }}
            />
            <Dialog
                open={isInfoActive.bonafide}
                content={
                    <Flex column gap="gap.small">
                        <Divider fitted />
                        <Flex column gap="gap.medium">
                            <Flex column gap="gap.small">
                                <Text content="Select Details *" weight="semibold" />
                                <Checkbox
                                    checked={isBonafide.dateOfJoining}
                                    label={state.userInfo.userManagerData.isSuccess && state.userInfo.userManagerData.status_code === "200" ?
                                        <span>Date Of Joining<br /> {getDDMMYYYFormat(state.userInfo.userManagerData?.user_details[0]?.Doj)}</span>
                                        : <span></span>
                                    }
                                    onChange={() => setIsBonafide({ ...isBonafide, dateOfJoining: !isBonafide.dateOfJoining })}
                                />
                                <Checkbox label="Current Address" checked={isBonafide.currentAdd} onChange={() => setIsBonafide({ ...isBonafide, currentAdd: !isBonafide.currentAdd })} />
                                <Checkbox label="Permanent Address" checked={isBonafide.permanentAdd} onChange={() => setIsBonafide({ ...isBonafide, permanentAdd: !isBonafide.permanentAdd })} />
                            </Flex>
                            <Flex column gap="gap.small">
                                <Text content="Concerned Authorities *" weight="semibold" />
                                <Input fluid value={isBonafide.concern} onChange={(e, v: any) => v.value === null ? setIsBonafide({ ...isBonafide, concern: "" }) : setIsBonafide({ ...isBonafide, concern: v.value })} />
                            </Flex>
                            <Flex column gap="gap.small">
                                <Text content="Reason *" weight="semibold" />
                                <TextArea rows={3} value={isBonafide.reason} onChange={(e, v: any) => v.value === null ? setIsBonafide({ ...isBonafide, reason: "" }) : setIsBonafide({ ...isBonafide, reason: v.value })} />
                            </Flex>
                        </Flex>
                    </Flex>
                }
                header="Generate Bonafide Certificate"
                headerAction={{
                    content: <CloseIcon />,
                    onClick: () => {
                        setIsInfoActive({ bonafide: false });
                        isBonafideResetHandler();
                    }
                }}
                footer={
                    <Flex gap="gap.medium" hAlign="end" vAlign="center">
                        <Button content="Cancel" onClick={() => {
                            setIsInfoActive({ bonafide: false });
                            isBonafideResetHandler();
                        }} />
                        <Button content="Generate"
                            loading={state.documentData.generateBonafideData.isLoading}
                            disabled={
                                !((isBonafide.dateOfJoining || isBonafide.currentAdd || isBonafide.permanentAdd) && isBonafide.concern !== "" && isBonafide.reason !== "")} primary onClick={() => getGenerateBonafideCertificate()} />
                    </Flex>
                }
                styles={QueryData.isTaskModuleActive ? { width: "500px", boxShadow: "5px 5px 5px 5px #888888" } : {}}
                overlay={{ styles: QueryData.isTaskModuleActive ? { background: "none", paddingBlock: "5rem" } : {} }}

            />
        </React.Fragment >
    );
};
export default DocumentCenter;