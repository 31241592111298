// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ApplyLeave .approveText {
  color: grey;
}
.ApplyLeave .quick-pill-active {
  background: rgb(91, 95, 199);
  justify-content: center;
  color: white;
  cursor: pointer;
}
.ApplyLeave .quick-pill-active:hover {
  background: rgb(91, 95, 199);
}
.ApplyLeave .quick-pill {
  justify-content: center;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/ApplyLeave/applyLeave.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;AAEE;EACE,4BAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;AAAJ;AACI;EACE,4BAAA;AACN;AAEE;EACE,uBAAA;EACA,eAAA;AAAJ","sourcesContent":[".ApplyLeave {\n  .approveText {\n    color: grey;\n  }\n  .quick-pill-active {\n    background: rgb(91, 95, 199);\n    justify-content: center;\n    color: white;\n    cursor: pointer;\n    &:hover {\n      background: rgb(91, 95, 199);\n    }\n  }\n  .quick-pill {\n    justify-content: center;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
