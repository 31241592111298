// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop_zone {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-width: 2px;
  border-radius: 2px;
  border-color: "#F5F5F5";
  border-style: dashed;
  /* background-color: #fafafa; */
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/components/Attachment/attachment.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,uBAAA;EACA,oBAAA;EACA,+BAAA;EACA,cAAA;EACA,aAAA;EACA,oCAAA;AACJ","sourcesContent":[".drop_zone {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 10px;\n    border-width: 2px;\n    border-radius: 2px;\n    border-color: \"#F5F5F5\";\n    border-style: dashed;\n    /* background-color: #fafafa; */\n    color: #bdbdbd;\n    outline: none;\n    transition: border .24s ease-in-out;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
