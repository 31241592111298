// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card_design {
  border: 2px solid #585A96;
  border-radius: 2px;
}

.visitor_card_button {
  margin-top: 75px;
}

.card_design_content_1 {
  font-size: large;
}`, "",{"version":3,"sources":["webpack://./src/components/ViewPhysicalPreview/viewPhysicalPreview.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ","sourcesContent":[".card_design {\n    border: 2px solid #585A96;\n    border-radius: 2px;\n}\n\n.visitor_card_button {\n    margin-top: 75px;\n}\n\n.card_design_content_1 {\n    font-size: large;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
