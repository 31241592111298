// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ACR-Approvals {
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 5px !important;
}
.ACR-Approvals .Acr-filter-box {
  padding: 0.8rem 1rem;
}
.ACR-Approvals .acrStatus,
.ACR-Approvals .acrStatus:hover {
  border-left: 5px solid;
  border-start-start-radius: 5px solid;
  border-radius: 4px;
}
.ACR-Approvals .approved,
.ACR-Approvals .approved:hover {
  border-left-color: #009E73;
}
.ACR-Approvals .rejected,
.ACR-Approvals .rejected:hover {
  border-left-color: rgb(231, 53, 80);
}
.ACR-Approvals .pending,
.ACR-Approvals .pending:hover {
  border-left-color: rgb(255, 204, 0);
}
.ACR-Approvals .dataTable {
  position: relative;
  border: 1px solid #efefef;
  border-radius: 4px;
  overflow: overlay;
  min-height: 150px;
}
.ACR-Approvals .dataTable .tableHead {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  min-width: min-content;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.428;
}
.ACR-Approvals .dataTable .ui-table__row {
  min-width: min-content;
  min-height: 2.5rem;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/container/ACRApprovals/acrApprovals.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,qBAAA;EACA,6BAAA;AACF;AACE;EACE,oBAAA;AACJ;AAEE;;EAEE,sBAAA;EACA,oCAAA;EACA,kBAAA;AAAJ;AAGE;;EAEE,0BAAA;AADJ;AAIE;;EAEE,mCAAA;AAFJ;AAKE;;EAEE,mCAAA;AAHJ;AAME;EACE,kBAAA;EACA,yBAAA;EACA,kBAAA;EAEA,iBAAA;EACA,iBAAA;AALJ;AAQI;EACE,gBAAA;EACA,MAAA;EACA,QAAA;EACA,OAAA;EACA,UAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AANN;AAUI;EACE,sBAAA;EACA,kBAAA;EACA,YAAA;AARN","sourcesContent":[".ACR-Approvals {\n  margin: 0 !important;\n  padding: 0 !important;\n  border-radius: 5px !important;\n\n  .Acr-filter-box {\n    padding: 0.8rem 1rem;\n  }\n\n  .acrStatus,\n  .acrStatus:hover {\n    border-left: 5px solid;\n    border-start-start-radius: 5px solid;\n    border-radius: 4px;\n  }\n\n  .approved,\n  .approved:hover {\n    border-left-color: #009E73;\n  }\n\n  .rejected,\n  .rejected:hover {\n    border-left-color: rgb(231, 53, 80);\n  }\n\n  .pending,\n  .pending:hover {\n    border-left-color: rgb(255, 204, 0);\n  }\n\n  .dataTable {\n    position: relative;\n    border: 1px solid #efefef;\n    border-radius: 4px;\n    // height: calc(100vh - 485px);\n    overflow: overlay;\n    min-height: 150px;\n\n\n    .tableHead {\n      position: sticky;\n      top: 0;\n      right: 0;\n      left: 0;\n      z-index: 2;\n      min-width: min-content;\n      font-weight: 600;\n      font-size: 0.875rem;\n      line-height: 1.428;\n\n    }\n\n    .ui-table__row {\n      min-width: min-content;\n      min-height: 2.5rem;\n      height: auto;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
