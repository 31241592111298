// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoxField {
  width: 100%;
  border: 1px solid #efefef;
  border-radius: 4px;
  position: relative;
  min-height: 150px;
  overflow: auto;
}
.BoxField .viewTable {
  height: 100%;
  position: relative;
  min-height: 150px;
  width: 100%;
}
.BoxField .viewTableHead {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
.BoxField .ui-table__row {
  min-height: 2.5rem;
  min-width: min-content;
}
.BoxField .status-dnf {
  height: inherit;
}`, "",{"version":3,"sources":["webpack://./src/components/ViewStatus/viewStatus.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;EAEA,iBAAA;EACA,cAAA;AAAJ;AAEI;EACI,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;AAAR;AAGI;EACI,gBAAA;EACA,MAAA;EACA,QAAA;EACA,OAAA;EACA,UAAA;AADR;AAII;EACI,kBAAA;EACA,sBAAA;AAFR;AAKI;EACI,eAAA;AAHR","sourcesContent":[".BoxField {\n    width: 100%;\n    border: 1px solid #efefef;\n    border-radius: 4px;\n    position: relative;\n    // height: calc(100vh / 2 - 100px);\n    min-height: 150px;\n    overflow: auto;\n\n    .viewTable {\n        height: 100%;\n        position: relative;\n        min-height: 150px;\n        width: 100%;\n    }\n\n    .viewTableHead {\n        position: sticky;\n        top: 0;\n        right: 0;\n        left: 0;\n        z-index: 1;\n    }\n\n    .ui-table__row {\n        min-height: 2.5rem;\n        min-width: min-content;\n    }\n\n    .status-dnf {\n        height: inherit;\n        // overflow: scroll;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
