// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iBtnBox {
  padding: 0.5rem;
  background-color: #5B62C0;
  color: white;
  border-radius: 4px;
}

.iBtnFlex {
  padding: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/RaiseTicket/raiseTicket.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,yBAAA;EACA,YAAA;EACI,kBAAA;AACR;;AACA;EACI,eAAA;AAEJ","sourcesContent":[".iBtnBox{\n    padding: 0.5rem;\n    background-color: #5B62C0;\n    color: white;\n        border-radius: 4px;\n}\n.iBtnFlex{\n    padding: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
